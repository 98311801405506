<template>
  <el-dialog :visible.sync="dialogVisible" width="500px" :before-close="handleClose" append-to-body :show-close="false">
    <h2 style="text-align: center;color: red;padding-bottom: 20px;">{{$t('Upuserpassd.系统强制要求您更新密码')}}</h2>
    <div style="width: 400px;margin: 0 auto;">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item :label="$t('Upuserpassd.用户名')">
          <span>{{ $store.state.Login.User }}</span>
        </el-form-item>
        <el-form-item :label="$t('Upuserpassd.旧密码')">
          <el-input v-model="Jpassword" type="password" show-password :placeholder="$t('Upuserpassd.请输入旧密码')"></el-input>
        </el-form-item>
        <el-form-item :error="error" :label="$t('Upuserpassd.新密码')">
        <el-input
          v-model="form.Password"
          type="password"
          :placeholder="$t('Upuserpassd.请输入新密码')"
          @input="validateInput"
          show-password
        />
      </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="Serverdata">{{$t('Upuserpassd.确定')}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data () {
    return {
      dialogVisible: true,
      form: {
        Password: ''
      },
      Jpassword: '',
      error: ''
    }
  },
  components: {
  },
  created () {
  },
  mounted () {
  },
  methods: {
    handleClose (done) {
      return this.$message.error('No Colse')
    },
    async Getdata () {
      try {
        const { data: res } = await this.$http.get('/api/Authenticate/Getdata')
        if (res.status !== 200) return this.$message.error(res.msg)
        const myChart = this.$echarts.init(document.getElementById('homequanb'))
        window.onresize = function () {
          myChart.resize()
        }
        this.homequanb.series[0].data = res.response
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(this.homequanb)
      } catch (error) {
        return this.$message.error(error)
      }
    },
    validateInput () {
      // 正则表达式
      const lengthPattern = /^(?!.*(.).*\1{5})[a-zA-Z0-9!@#$%^&*]{8,16}$/
      const charTypePattern = /^(?=.*[a-zA-Z])(?=.*[0-9])|(?=.*[a-zA-Z])(?=.*[!@#$%^&*])|(?=.*[0-9])(?=.*[!@#$%^&*])/
      const sequencePattern = /^(?!.*(\d)\1{5})(?!.*([a-zA-Z])\2{5})(?!.*(0123456|1234567|abcdefg|ABCDEFG))/
      const forbiddenPattern = /(nienyi)/i // 禁止出现 "nienyi"，忽略大小写

      // 校验密码
      if (!lengthPattern.test(this.form.Password)) {
        this.error = this.$t('Upuserpassd.密码规则')
      } else if (!charTypePattern.test(this.form.Password)) {
        this.error = this.$t('Upuserpassd.密码必须包含字母数字符号中的至少两种')
      } else if (!sequencePattern.test(this.form.Password)) {
        this.error = this.$t('Upuserpassd.密码不能包含连续字符或重复6位以上字符')
      } else if (forbiddenPattern.test(this.form.Password)) {
        this.error = this.$t('Upuserpassd.密码不能包含nienyi字样')
      } else {
        this.error = '' // 校验通过，清空错误信息
      }
    },
    async Serverdata () {
      if (this.Jpassword !== this.$store.state.Login.Password) return this.$message.error(this.$t('Upuserpassd.旧密码不正确请重新输入'))
      if (this.form.Password === '') return this.$message.error(this.$t('Upuserpassd.请输入新密码'))
      if (this.error !== '') return this.$message.error(this.$t('Upuserpassd.新密码强度不符合需求请重新填写'))
      const userdata = this.$store.state.Login
      userdata.Password = this.form.Password
      const { data: res } = await this.$http.post('/api/Admin/UserUp', userdata)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.$message.success(this.$t('Upuserpassd.修改成功请重新登录'))
      this.dialogVisible = false
      this.$store.state.TabComponent = 'Home'
      window.sessionStorage.setItem('token', '')
      this.$router.push('/Login')
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    padding: 0;
}
</style>
